<template>
    <b-modal :ref="modalRef" :title="'Édition de ' + user.prenom + ' ' + user.nom" hide-footer @hidden="destroyModal"
             size="lg">
        <b-col lg="8" offset-lg="2" class="text-center">
            <b-form @submit.prevent="submit">
                <b-form-group label="Prénom">
                    <b-form-input v-model="form.prenom" type="text" required/>
                </b-form-group>

                <b-form-group label="Nom">
                    <b-form-input v-model="form.nom" type="text" required/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Optionnel'" slot="label">
                        Pseudonyme
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.pseudo" type="text"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Idéalement une adresse INSA'" slot="label">
                        Adresse mail
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.email" type="email" required/>
                </b-form-group>

                <b-popover target="popover-roles" triggers="hover focus">
                    <template slot="title">Doc des roles :</template>
                    <p>Vous pouvez cumuler les roles pour un utilisateur, ou ne mettre que le role "le plus fort", au choix</p>
                    <hr class="mt-2 mb-2"/>
                    <h4>Hiérarchie des roles :</h4>
                    <p class="mb-0">
                        <em>Utilisateur labmda</em> = Tout utilisateur avec un compte valide
                    </p>
                    <p class="text-center mt-1 mb-1"><strong><</strong></p>
                    <p class="mb-0">
                        <em>Association extérieure</em> = Représentant d'une asso autre que le Cluji
                    </p>
                    <p class="text-center mt-1 mb-1"><strong>~</strong></p>
                    <p class="mb-0">
                        <em>Membre du Cluji</em> = Membre du Cluji pour cette année
                    </p>
                    <p class="text-center mt-1 mb-1"><strong><</strong></p>
                    <p>
                        <em>Bureau du Cluji</em> = Au bureau du Cluji pour cette année
                    </p>
                </b-popover>
                <b-form-group>
                    <label slot="label" id="popover-roles">
                        Role(s)
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <multi-select v-model="form.roles"
                                  :options="rolesAvailable"
                                  multiple="true"
                                  :close-on-select="false"
                                  track-by="id"
                                  :clear-on-select="false"
                                  :preserve-search="false"
                                  label="nom"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Essayez d\'être cohérent avec les roles'"
                           slot="label">
                        Association(s)
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <multi-select v-model="form.associations"
                                  :options="sortedAssos"
                                  multiple="true"
                                  :close-on-select="false"
                                  track-by="id"
                                  :clear-on-select="false"
                                  :preserve-search="false"
                                  label="nom"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Si l\'association ne se trouve pas encore dans la liste, ajoutez-la ici'"
                           slot="label">
                        Ajouter une association
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-input-group>
                        <b-form-input v-model="form.otherAsso" type="text" v-on:keydown.enter.prevent='addAsso'/>

                        <b-button variant="success" @click="addAsso" slot="append">
                            <font-awesome-icon icon="plus"/>
                        </b-button>
                    </b-input-group>
                </b-form-group>

                <b-btn type="submit" variant="success" class="m-3" :disabled="loading">
                    <font-awesome-icon icon="check"/>
                    Valider
                    <font-awesome-icon icon="sync" spin v-if="loading"/>
                </b-btn>
            </b-form>
        </b-col>
    </b-modal>
</template>

<script>
    import {apiPath}       from '@/util/http';
    import {isWebcretaire} from '@/util/auth';

    import modalMixin        from '@/mixin/modalMixin';
    import assoSelectorMixin from '@/mixin/assoSelectorMixin';
    import MultiSelect       from '@/components/MultiSelect'

    export default {
        name: "AdminEditUserModal",
        components: {MultiSelect},
        mixins: [modalMixin, assoSelectorMixin],
        props: {
            user: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            loading: false,
            modalRef: 'adminEditUserModal',
            form: {},
            rolesAvailable: [
                {
                    id: 'ROLE_USER',
                    nom: 'Utilisateur lambda'
                },
                {
                    id: 'ROLE_ASSO',
                    nom: 'Association extérieure'
                },
                {
                    id: 'ROLE_CLUJI',
                    nom: 'Membre du Cluji'
                },
                {
                    id: 'ROLE_BUREAU',
                    nom: 'Bureau du Cluji'
                }
            ]
        }),
        methods: {
            submit() {
                this.loading = true;

                this.axios
                    .post(apiPath('admin_edit_user', {utilisateur: this.user.id}), {
                        ...this.form,
                        roles: this.form.roles.map(({id}) => id) // Extract IDs from roles (do not send the whole object)
                    })
                    .then(() => {
                        this.$toaster.success("Utilisateur modifié avec succès");
                        this.callback();
                        this.hideModal();
                    })
                    .catch(error => this.$toaster.error(error.response.data.error || 'Erreur inconnue'))
                    .finally(() => this.loading = false);
            }
        },
        mounted() {
            this.form              = {...this.form, ...this.user};
            this.form.associations = [...this.user.associations];
            if (isWebcretaire()) this.rolesAvailable.push({id: 'ROLE_WEBCRETAIRE', nom: 'Webcrétaire'});
            this.form.roles = this.rolesAvailable.filter(role => this.user.roles.includes(role.id));
        }
    }
</script>
