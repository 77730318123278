import {apiPath}        from '@/util/http';
import {naturalSortAsc} from '@/util/sort';

export default {
    data: () => ({
        associations: [],
        form: {
            associations: [],
            otherAsso: null
        }
    }),
    computed: {
        sortedAssos() {
            return this.associations.sort(naturalSortAsc);
        }
    },
    methods: {
        loadAssos() {
            this.axios.get(apiPath('list_assos_quickview'))
                .then(response => this.associations = response.data)
                .catch(() => this.$toaster.error("Impossible de récupérer la liste des associations"));
        },
        addAsso() {
            let newAssoName = this.form.otherAsso;

            if (newAssoName.length) {
                if (!this.associations.filter(asso => asso.nom === newAssoName).length) {
                    this.associations.push({id: 0, nom: newAssoName});
                }

                if (!this.form.associations.filter(asso => asso.nom === newAssoName).length) {
                    this.form.associations.push(this.associations.filter(asso => asso.nom === newAssoName)[0]);
                }
            }

            this.form.otherAsso = null;
        }
    },
    mounted() {
        this.loadAssos();
    }
};